import React from "react";

const Iframe = () => {
  return (
    <>
      <div className="form" style={{ color: "white", transition: "all 1s" }}>
        iframe
      </div>
    </>
  );
};

export default Iframe;
