import React, { useState } from "react";
import Iframe from "../component/Iframe";
import Layout from "../component/Header-Ch";
import Rules from "../component/Rules-Ch";

const Recording = () => {
  const [active, setActive] = useState(true);
  return (
    <>
      <Layout
        url={"/thai"}
        header={"decreaseHeader"}
        image={" decrease-img"}
        position={"decrease"}
        bookanime={"books"}
        menuAnime={"appearanceMenu"}
        animeTiktok={"tiktook"}
        animeFacebook={"facebok"}
        animeInstagram={"instagram"}
      />
      <div style={{ overflow: "auto", paddingBottom: "100px" }} className="anim  content">
        <div
          style={{ color: "white", fontSize: "20px", padding: "22px" }}
          className="jobs-content thub"
        >
          สวัสดีค่ะ คุณต้องการเป็นส่วน <br />
          หนึ่งของทีมเราไหม <br />
          <br />
          <div style={{ color: "#944a71" }}>
          ก่อนส่งประวัติย่อของคุณ   <br />
มาให้เรา กรุณาอ่านข้อกำหนด   <br />
อย่างละเอียด!  <br /><br />

เรากำลังมองหาผู้เชี่ยวชาญ  <br />
ที่มีประสบการณ์มากกว่า 1 ปี  <br />
เฉพาะผู้ที่มีความมั่นใจใน  <br />
ทักษะของตน และมีความรู้  <br />
พื้นฐานด้านภาษาอังกฤษ  <br /><br />

ผู้เชี่ยวชาญด้านการทำเล็บ  <br />
มือและเล็บเท้า การต่อขนตา -  <br />
เฉพาะผู้ที่มีทักษะการทำ  <br />
เล็บมือและเล็บเท้า  <br /><br />

ในระหว่างทำงาน เพื่อที่จะ  <br />
ไปถึงระดับของร้านค้าของเรา  <br />
คุณจะต้องผ่านการฝึกอบรม  <br />
ใหม่  <br /><br />

คุณต้องตั้งใจฟังอาจารย์  <br />
ผู้สอนและปฏิบัติตามคำแนะนำ  <br />
ของเขา รวมถึงปฏิบัติตาม  <br />
กฎของร้านทุกประการ  <br /><br />

เราต้องการคนที่อยากมีรายได้  <br />
สูงๆ 24,000-35,000 บาท และ  <br />
พร้อมทำงานที่เดียวอย่าง  <br />
ต่อเนื่อง มีความรับผิดชอบ  <br />
และพร้อมปฏิบัติตามกฎระเบียบ  <br />
อย่างเคร่งครัด  <br /><br />

หากคุณรู้สึกว่านี่คือสิ่งที่  <br />
คุณต้องการ และคุณมีประสบการณ์  <br />
เพียงพอสำหรับระดับสูงสุด  <br />
โปรดส่งประวัติย่อของคุณ  <br />
เป็นภาษาอังกฤษมาให้เรา  <br /><br />

ในเรซูเม่ของคุณ คุณต้องตอบ  <br />
คำถามต่อไปนี้:  <br />
1. ชื่อของคุณ  <br />
2. ประสบการณ์การทำงาน  <br />
3. อายุของคุณ  <br />
4. สถานภาพสมรส  <br />
5. คุณอาศัยอยู่ห่างจากพระราม  <br />
9 แค่ไหน  <br />
6. แนบรูปถ่ายผลงานของคุณ  <br />
เพื่อให้เราสามารถประเมิน  <br />
คุณภาพได้  <br /><br />

ส่งมาที่ LINE: katstrela  <br /><br />

เราทำงานตั้งแต่ 10:45 ถึง  <br />
20:00 น. คุณจะได้ทำงานในร้าน  <br />
เสริมสวยที่สวยงามและหรูหรา  <br />
ที่ที่สุดแห่งหนึ่งในกรุงเทพฯ  <br />
เราเสนออัตราค่าจ้าง 800 บาท  <br />
ต่อวัน + 10% ของบิลของลูกค้า  <br />
เราจัดหาอุปกรณ์ที่ทันสมัย  <br />
ที่สุด และวัสดุที่ดีที่สุด  <br />
เรายังมีระบบโบนัสอีกด้วย  <br /><br />

เรารอคอยที่จะได้ยินจาก  <br />
ผู้สมัครที่มีคุณสมบัติเหมาะสม!<br /><br />
เรารอคอยที่จะได้ยินจากผู้สมัครที่มีคุณสมบัติเหมาะสม!

          </div>
        </div>
      </div>
    </>
  );
};

export default Recording;
