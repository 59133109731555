import React from "react";
import Layout from "../component/Header-Thai";

const Info = () => {
  return (
    <>
      <Layout
        header={"decreaseHeader"}
        image={" decrease-img"}
        position={"decrease"}
        bookanime={"books"}
        menuAnime={"appearanceMenu"}
        animeTiktok={"tiktook"}
        animeFacebook={"facebok"}
      />
      <div className="content">
        
        <div>
        สวัสดีค่ะ 
        <br />
        คุณต้องการเป็นส่วนหนึ่งของทีมเราไหม

        </div>
        <div>
        ก่อนส่งประวัติย่อของคุณมาให้เรา <br />
        กรุณาอ่านข้อกำหนดอย่างละเอียด!  <br />
        เรากำลังมองหาผู้เชี่ยวชาญ <br />
        ที่มีประสบการณ์มากกว่า 1 ปี <br />
        เฉพาะผู้ที่มีความมั่นใจในทักษะ <br /> 
        ของตนและมีความรู้พื้นฐานด้านภาษาอังกฤษ <br />
        ผู้เชี่ยวชาญด้านการทำเล็บมือและเล็บเท้า <br />
        การต่อขนตา - เฉพาะผู้ที่มีทักษะการ <br />
        ทำเล็บมือและเล็บเท้า <br />
        ในระหว่างทำงาน เพื่อที่จะไปถึง <br />
        ระดับของร้านค้าของเรา <br />
        คุณจะต้องผ่านการฝึกอบรมใหม่ <br />
        คุณต้องตั้งใจฟังอาจารย์ผู้ส <br />
        อนและปฏิบัติตามคำแนะนำของเขา <br />
        รวมถึงปฏิบัติตามกฎของร้านทุกประการ <br />
        เราต้องการคนที่อยากมีรายได้สูงๆ <br />
        24,000-35,000 บาทและพร้อมทำงาน <br />
        ที่เดียวอย่างต่อเนื่อง มีความรับผิดชอบ <br />
        และพร้อมปฏิบัติตามกฎระเบียบอย่างเคร่งครัด <br />
        หากคุณรู้สึกว่านี่คือสิ่งที่คุณต้องการ <br />
        และคุณมีประสบการณ์เพียงพอสำหรับ <br />
        ระดับสูงสุด โปรดส่งประวัติย่อของคุณ <br />
        เป็นภาษาอังกฤษมาให้เรา <br />
        ในเรซูเม่ของคุณ คุณต้องตอบคำถามต่อไปนี้: <br />
        
        </div>
      </div>
    </>
  );
};

export default Info;
